






























import { computed, defineComponent } from "@vue/composition-api";

export default defineComponent({
  props: {
    error: {}
  },
  setup(props) {
    const logo = computed(() => require("@/assets/img/visa_master_small.png"));

    const errorMessage = computed(() => JSON.stringify(props.error));

    return { logo, errorMessage };
  }
});
